<template>
  <Candidates
    :user="user"
    :avatarText="avatarText"
    :searchText="searchText"
    fetchMethod="fetchMyExams"
    header="CANDIDATE_LIST_HEADERS"
    headerText="All Candidates"
  />
</template>

<script>
import { Candidates } from 'exam-service-web-lib';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CandidatesPage',
  components: {
    Candidates,
  },
  computed: {
    ...mapState(['searchText']),
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', ['avatarText']),
  },
};
</script>

<style></style>
